<template>
<div id="orderManage">
  <!--头部搜索-->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="searKeyword" v-model="sendData.keyword" placeholder="请输入用户ID查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;" @click="searKeyword" class="el-icon-search"></i>
      </div>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="exportData">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="row margin-bottom-16 flex-start">
      <li v-for="(v,i) in dataList" :key="i">
			  <div class="fj_post">
			  	<div class="details">
             <p class="flex justify-between">ID：<span>{{v.feedback_id}}</span></p>
			  	   <p class="flex justify-between">微信昵称：<span>{{v.user_nickname}}</span></p>
			  	   <p class="flex justify-between">注册时间：<span>{{v.user_create_time*1000|getDate}}</span></p>
             <p class="flex  justify-between">联系电话：<span>{{v.feedback_contact}}</span></p>
             <p class="flex  justify-between">地区：<span>{{v.feedback_area}}</span></p>
             <p class="flex  justify-between">大学：<span>{{v.feedback_school}}</span></p>
             <p class="flex">当选理由
              <span @click="readInfo(v.feedback_content)" style="color: #4E9F5B;cursor:pointer;margin-left:20px">查看</span>
              <!-- <span @click="addStation(v)" style="color: #4E9F5B;cursor:pointer;position: absolute;right:0">成为站长</span> -->
             </p>
			  	</div>
			  </div>
		  </li> 
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getTabList"
      :page.sync= "sendData.page"
      :limit.sync="sendData.limit"/>
  </div>
  <!-- 表格弹窗 -->
  <!-- 地址表格弹窗 -->
    <el-dialog
    id="infoModelBox"
    :visible.sync="showInfoModel"
    :close-on-press-escape='false'
    :close-on-click-modal='false'
    title="查看描述信息"
    close="closeInfoModel"
    >

    <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left" style="min-height: 225px;">
      <div v-html="content"></div>
    </div>

  </el-dialog>

  <el-dialog id="imgModelBox" close="closeImgModel" :visible.sync="showImgModel" title="查看附件" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body>

    <div class="spadding-16 text-14 text-333 margin-top-24 text-left flex flex-wrap" style="min-height: 225px;">
      <div v-for="(item,index) in imgContent" :key="index">
        <img :src="item" style="margin-bottom: 16px;margin-right: 10px;width: 120px;height: 120px;" >
      </div>
    </div>

  </el-dialog>
</div>
</template>
<script>
import {feedBack} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
      return{
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
       /* 查看附件弹窗*/
        showImgModel:false,
        showInfoModel:false,
        sendData:{
          feedback_type:2,//意见反馈
          keyword:'',
          page:1,
          limit:8
        },
        content:'',
        imgContent:''
      }
    },
    created(){
      this.getTabList()//用户反馈列表数据
    },
    methods:{
      //获取意见反馈列表
      getTabList(){
        feedBack(this.sendData).then(res=>{
          console.log('申请站长'+res.data.data)
          let {list,page,count,limit}=res.data.data
          console.log(list)
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }else if(res.data.code=='1'&&res.data.data.csv_file){
            window.open(res.data.data.csv_file)
            this.sendData.limit=this.rows
          }
        })
      },
      addStation(info){
          console.log(info)
          localStorage.setItem('now_station_info',JSON.stringify(info))
          this.$router.push({path:'/stationCenter',query:{sqzz:1}})
      },
      //查看意见反馈
      readInfo(text){
        this.showInfoModel = true
        this.content = text
      },
      readImg(content){
        this.showImgModel = true
        this.imgContent = content
      },
      //搜索
      searKeyword(){
        // this.getTabList()
        // this.getTabList()
        // this.getTabList()
        console.log(this.sendData.keyword)
        if(!this.sendData.keyword) return this.getTabList();
        // return this.dataList.filter(v=>{
        //   console.log(v)
        //   return v.coupon_title.includes(this.sendData.keyword);
        // })
        var _this = this
        var newItems = []
        this.dataList.map(function(item){
          // console.log(item)
          if(!item.feedback_id.search(_this.sendData.keyword)){
            console.log(item)
            newItems.push(item)
          }
        })
        // return newItems
        console.log(newItems)
        this.dataList = newItems
      },
      /* 清空输入框 */
      emptyKeyword(e){
        if(e.length=='0'){
          this.sendData.page=1
          this.getTabList()
        }
      },
      //导出
      exportData(){
        this.sendData.page=0
        this.sendData.limit=9999
        this.getTabList()
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      // 关闭弹窗
      closeInfoModel() {
        this.showInfoModel = false
        this.getTabList()
      },
      closeImgModel() {
        this.showImgModel = false
        this.getTabList()
      }
    },
    components:{
      Pagination
    }
}
</script>
<style>
    #orderManage{
  width: 100%;
}
#orderManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
} 
#orderManage .searchDom{
    height: 70px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 24px 80px 4px 24px;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 60px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
}
#orderManage .searchList{
  padding: 16px;
  margin-top: 76px;
}  
#orderManage ul{
  list-style: none;
  margin-left: -16px;
  margin-right: 16px;
}
#orderManage .searchList li{
  width: 24%;
  margin-right: 15px;
}
#orderManage ul li .order_number{
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#orderManage ul li .order h1 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  display: block;
  margin-top: 2px;
}
#orderManage ul li .order font {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  float: right;
}
.order #order_state{
  margin-top: -15px;
}
.order span{
    display: block;
    white-space: nowrap;
    color: #323233;
    font-size: 14px;
    font-weight: bold;
}
.order em{
  display: block;
  white-space: nowrap;
  color: #323233;
  font-size: 14px;
}
.order p {
    color: #323233;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.order .order_kd{
  padding: 10px 14px;
  background: rgba(235,237,240,.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 20px;
}
.order .order_kd img{
  display: block;
  width: 80px;
  height: 36px;
  object-fit: cover;
  background: #ebedf0;
}
.order .order_kd i{
  display: block;
  width: 1px;
  height: 17px;
  background: #ebedf0;
  margin: 0 16px;
}
.order .order_kd .copy{
  display: block;
  white-space: nowrap;
  color: #79838b;
  font-size: 14px;
}
.pageNum {
    height: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: fixed;
    z-index: 20;
    left: 144px;
    right: 28px;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #000;
    font-size: 14px;
}
.fj_post {
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    border-left: 4px solid transparent;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 30px;
    /**height: 350px;**/
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
}
.fj_post .details{
  margin-top: 45px;
  margin-left: 15px;
}
.fj_post .thumb {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    float: left;
    margin-right: 30px;
    text-align: center;
}

.fj_post .details {
    flex: 1;
}
.fj_post .details p {
    margin-bottom: 10px;
    color: #6d6d6d !important;
    font-size: 14px;
    position: relative;
}
.fj_post .details span{margin-right: 5px;}
.fj_post:hover {
    border: 1px solid #345e3b;
    border-left: 4px solid #345e3b;
}

</style>